












































































































import {
    Component,
    Vue
} from "vue-property-decorator";
import {
    Dialog,
    Toast
} from "vant";
import Mixin from "@/js/mixins";

@Component({
    mixins: [Mixin]
})
export default class HomeShop extends Vue {
    public user_id = "";
    public user = {}; // 门店信息
    public order_nav = [{
            active: 1,
            text: "未付款订单",
            icon: "icon-couponValidNum",
            name: "no_pay"
        },
        {
            active: 2,
            text: "未发货订单",
            icon: "icon-daifahuo",
            name: "no_shipping"
        },
        {
            active: 3,
            text: "未收货订单",
            icon: "icon-fahuo",
            name: "no_take_shipping"
        }
    ]; // 导航列表
    goCargo() {
        this.$router.push('/home/cargo?id=' + this.user_id)
    }
    // 显示推荐码
    showParentName() {
        let user: any = this.user;
        Dialog.confirm({
            title: "推荐码",
            message: "您的推荐码为： " + user.user_name,
            confirmButtonText: "复制推荐码"
        }).then(() => {
            let copy: any = this.$refs.copy;
            copy.click();
        });
    }
    copy() {
        Toast("复制成功");
    }

    // 获取用户门店信息
    getUserInfo() {
        let _this = this;
        let user_id = this.user_id;
        this.$api.request({
            url: "user/users/user-info",
            data: {
                user_id
            },
            success(res) {
                // if (!res.data.parent_id) {
                //     // 推荐人未填，跳转到填写推荐人页面
                //     _this.$api.href("/user/parent?user_id=" + (user_id || ""));
                //     Toast("请填写推荐人");
                // }
                _this.user = res.data;
                console.log(_this.user)
            }
        });
    }

    init() {
        this.user_id = String(this.$route.query.id || "");
        console.log(this.user_id)
        if (this.user_id) {
            localStorage.setItem('nowUserId', this.user_id)
        }
        this.user = {};
        this.getUserInfo();
    }
}
